
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

class MessagesByLimits extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initial: false,
            value: [],
        };
    }

    componentDidMount() {
        this.setInitialValue()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value && !this.state.initial) {
            this.setInitialValue()
        }
    }

    setInitialValue = () => {
        if (this.props.value) {
            this.setState({ value: this.props.value, initial: true })
        }
    }


    addItem = () => {
        let value = this.state.value ? this.state.value : [];
        value.push({ limitFrom: '', limitTo: '', value: '' })

        this.setState({ value }, () => {
            this.save()
        })
    }
    removeItem = (idx) => {
        let value = this.state.value;
        if (value && value[idx]) {
            value.splice(idx, 1)
            this.setState({ value }, () => {
                this.save()
            })
        }

    }
    save = () => {
        let value = this.state.value;
        this.props.onChange(value)
    }


    render() {



        return (
            <div className='limits-field-wrap' style={{ marginTop: 20 }}>
                <h5>{this.props.label ? this.props.label : null}</h5>
                {
                    this.state.value.map((item, idx) => {
                        return (
                            <div className='limits-field-item-wrap'>
                                <div style={{ marginRight: 10 }}>
                                    <h6>Limit od:</h6>
                                    <input value={item.limitFrom} type='text' onChange={(e) => {
                                        let reg = /^\d+$/;
                                        let val = e.target.value;
                                        let check = true;
                                        if (val && !reg.test(val)) {
                                            check = false;
                                        }
                                        if (check) {
                                            let value = this.state.value;
                                            value[idx].limitFrom = e.target.value;
                                            this.setState({ value }, () => {
                                                this.save()
                                            })
                                        }

                                    }} />
                                </div>
                                <div style={{ marginRight: 10 }}>
                                    <h6>Limit do:</h6>
                                    <input value={item.limitTo} type='text' onChange={(e) => {
                                        let reg = /^\d+$/;
                                        let val = e.target.value;
                                        let check = true;
                                        if (val && !reg.test(val)) {
                                            check = false;
                                        }
                                        if (check) {
                                            let value = this.state.value;
                                            value[idx].limitTo = e.target.value;
                                            this.setState({ value }, () => {
                                                this.save()
                                            })
                                        }

                                    }} />
                                </div>
                                <div>
                                    <h6>Poruka:</h6>
                                    <input value={item.value} style={{ width: 300}} type='text' onChange={(e) => {
                                        let value = this.state.value;
                                        value[idx].value = e.target.value;
                                        this.setState({ value }, () => {
                                            this.save()
                                        })
                                    }} />
                                </div>
                                <div className='remove-item' onClick={() => this.removeItem(idx)}>
                                    &times;
                                </div>
                            </div>
                        )
                    })
                }

                <button onClick={() => this.addItem()} className='add-limit-btn'>Dodaj poruku</button>
            </div>
        );

    }
}

export default MessagesByLimits;