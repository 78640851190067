import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { Line, Polar } from 'react-chartjs-2';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { encode } from 'punycode';


class EbayPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    componentDidMount() {
        //this.props.handleLoader(true);
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }

        
        

    }


    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on("eBayCreateOffer", (data) => {
            console.log(data);
            if (data.successful){
                this.props[0].history.push('/ebay-offers');
            }
        });

        this.props.socketIOClient.emit("eBayCreateOffer", {_id: this.props[0].match.params._id});
    }


    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("eBayCreateOffer");
    }


    render() {

        return (
            <div className="content">
                {

                    (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

                }
                <div className="panel ">
                    <Container fluid className="panel-title">
                        <h1>eBay</h1>
                        <h6>eBay Settings</h6>

                    </Container>

                    <Container fluid>

                        <Row>
                            <Col lg="12">

                                <h3>{this.props.translate('Creating offer')}</h3>

                            </Col>
                        </Row>
                    </Container>

                </div>

            </div>
        );
    }
}

export default PageWithLayout(EbayPage);