import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { Line, Polar } from 'react-chartjs-2';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { encode } from 'punycode';


class EbayPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    componentDidMount() {
        //this.props.handleLoader(true);
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }




    }


    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on("testEbayAuth", (data) => {
            console.log(data);
            if (data.authenticated) {
                this.setState({ authenticated: true })
            }
        });

        this.props.socketIOClient.emit("testEbayAuth", {});

        if (this.props[0].location.search.indexOf('code') !== -1){
            this.props.socketIOClient.emit("eBayOAuth2", {
                code: this.props[0].location.search.replace("?code=","").replace("&expires_in=299","")
            })
        }

    }


    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("adminFetch");
    }


    render() {

        return (
            <div className="content">
                {

                    (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

                }
                <div className="panel ">
                    <Container fluid className="panel-title">
                        <h1>eBay</h1>
                        <h6>eBay Settings</h6>

                    </Container>

                    <Container fluid>

                        <Row>
                            <Col lg="12">

                                <Container fluid>
                                    <Row>
                                        

                                        {
                                            !this.state.authenticated ?


                                                <Col lg="12">
                                                    <a href="https://auth.ebay.com/oauth2/authorize?client_id=akeygmbh-akeygmbh-PRD-979687a0c-df216abd&response_type=code&redirect_uri=a-key-gmbh-akeygmbh-akeygm-qhkyia&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly" className="button">eBay SignIn</a>
                                                </Col>

                                                :

                                                <Col lg="12">
                                                    <h3>{this.props.translate('eBay Authenticated')}</h3>
                                                </Col>

                                            /*<Col lg="6">
                                                <button className="button" onClick={() => {
                                                    this.props.socketIOClient.emit("eBayOAuth2", {
                                                        code: this.state.code
                                                    })
                                                }}>Get oAuthToken</button>
                                            </Col>*/

                                        }
                                    </Row>

                                </Container>

                            </Col>
                        </Row>
                    </Container>

                </div>

            </div>
        );
    }
}

export default PageWithLayout(EbayPage);