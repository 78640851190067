
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

function generateAlias(str) {
    str = str.toLowerCase();
    str = str.replace(/\s\s+/g, ' ');
    str = str.replace(/ /g, '-');
    str = str.replace(/\./g, '-');
    str = str.replace(/\,/g, '-');
    str = str.replace(/š/g, 's');
    str = str.replace(/č/g, 'c');
    str = str.replace(/ć/g, 'c');
    str = str.replace(/đ/g, 'dj');
    str = str.replace(/ž/g, 'z');
    return str;
}

class ListOfProducts extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            modal: false,
            initial: false,
            value: {},
            selectedProduct: -1,
            selectedPackages: []
        };
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                openMenu: false
            });
        }
    }
    componentDidMount() {
        this.setInitialValue()
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value && !this.state.initial) {
            this.setInitialValue()
        }
    }

    setInitialValue = () => {
        if (this.props.value) {
            this.setState({ value: this.props.value, initial: true })
        }
    }


    addItem = () => {
        let value = this.state.value ? this.state.value : {};
        let selectedPackages = this.state.selectedPackages;
        let selectedProduct = this.state.selectedProduct;

        value[selectedProduct] = selectedPackages;

        this.setState({ value, selectedProduct: -1, selectedPackages: [] }, () => {
            this.save()
        })
    }
    removeItem = (item) => {
        let value = this.state.value;
        if (item && item._id && value && value[item._id]) {
            delete value[item._id]
        }
        this.setState({ value }, () => {
            this.save()
        })
    }
    save = () => {
        let value = this.state.value;
        this.props.onChange(value)
    }


    render() {
        let allProducts = this.props.allProducts ? this.props.allProducts : []
        let packages = [];


        if (this.state.selectedProduct && allProducts.filter(el => el._id === this.state.selectedProduct).length) {
            packages = allProducts.filter(el => el._id === this.state.selectedProduct)[0].package;
        }


        let products = [];
        if (this.state.value && Object.keys(this.state.value).length) {
            for (var key in this.state.value) {
                let product = allProducts.filter(el => el._id === key);
                if (product && product[0]) {
                    let selPackages = [];
                    if (this.state.value[key] && this.state.value[key].length && product[0].package) {
                        for (let i = 0; i < this.state.value[key].length; i++) {
                            if (product[0].package[this.state.value[key][i]]) {
                                selPackages.push(product[0].package[this.state.value[key][i]])
                            }
                        }
                    }
                    products.push({
                        Name: product[0].Name,
                        Alias: product[0].Alias,
                        _id: product[0]._id,
                        selectedPackages: selPackages
                    })
                }
            }
        }
        const classNameWrap = `list-of-products-field-wrap ${this.props.className ? this.props.className : ''}`
        console.log(this.props)
        return (
            <div className={classNameWrap}>
                <div className={this.state.modal ? 'list-of-products-button active-button' : 'list-of-products-button'} onClick={() => this.setState({ modal: true })}>
                    {this.props.label ? this.props.label : 'Popust na kolicinu'}
                </div>

                {this.state.modal ? <div className='list-of-products-modal-overlay' onClick={() => this.setState({ modal: false })} /> : null}

                {
                    this.state.modal ?
                        <div className='list-of-products-modal-wrap'>
                            <div className='list-of-products-modal-header'>
                                <h6>Proizvodi</h6>
                                <div onClick={() => this.setState({ modal: false })}>&times;</div>
                            </div>
                            <div className='list-of-products-modal-body'>
                                <div className='list-of-products-modal-body-left'>
                                    <div className='search-select-products-wrap' ref={(node) => this.wrapperRef = node}>
                                        <input type='text' value={this.state.searchProduct} placeholder='Izaberi' onFocus={() => this.setState({ openMenu: true })} onChange={(e) => this.setState({ searchProduct: e.target.value })} />
                                        {
                                            this.state.openMenu ?
                                                <div className='search-select-products-menu'>
                                                    {
                                                        !allProducts.filter(item => !this.state.searchProduct || (this.state.searchProduct && ((item.Alias && item.Alias.toLowerCase().indexOf(this.state.searchProduct.toLowerCase()) !== -1) || (item.Name && item.Name.toLowerCase().indexOf(this.state.searchProduct.toLowerCase()) !== -1)))).length ?
                                                            <div style={{ padding: '5px 10px' }}>Lista je prazna</div>
                                                            :
                                                            allProducts.filter(item => !this.state.searchProduct || (this.state.searchProduct && ((item.Alias && item.Alias.toLowerCase().indexOf(this.state.searchProduct.toLowerCase()) !== -1) || (item.Name && item.Name.toLowerCase().indexOf(this.state.searchProduct.toLowerCase()) !== -1)))).map((item, idx) => {
                                                                return (
                                                                    <div key={idx} className={this.state.selectedProduct === item._id ? 'search-select-products-item search-select-products-item-selected' : 'search-select-products-item'} onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        this.setState({ selectedProduct: item._id, selectedPackages: [] }, () => {
                                                                            this.setState({ openMenu: false, searchProduct: '' })
                                                                        })
                                                                    }}>{item.Alias} - {item.Name}</div>
                                                                )
                                                            })
                                                    }
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    {/* <select className='select-field' type='select' value={this.state.selectedProduct} onChange={(e) => this.setState({ selectedProduct: e.target.value, selectedPackages: [] })}>
                                        <option value={-1}>Izaberi</option>
                                        {
                                            allProducts.map((item, idx) => {
                                                return (
                                                    <option key={idx} value={item._id}>{item.Alias} - {item.Name}</option>
                                                )
                                            })
                                        }
                                    </select> */}
                                    {
                                        packages && packages.length ?
                                            <div className='packages-wrap'>
                                                <h6>Pakovanja: </h6>
                                                {
                                                    packages.map((item, idx) => {
                                                        return (
                                                            <div className='package-item-wrap' key={idx}>
                                                                <div className={this.state.selectedPackages.indexOf(idx) !== -1 ? 'active-packege' : 'inactive-packege'} onClick={() => {
                                                                    let selectedPackages = this.state.selectedPackages;
                                                                    if (selectedPackages.indexOf(idx) === -1) {
                                                                        selectedPackages.push(idx)
                                                                    } else {
                                                                        selectedPackages.splice(selectedPackages.indexOf(idx), 1)
                                                                    }
                                                                    this.setState({ selectedPackages })
                                                                }}></div>
                                                                {item.name}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        this.state.selectedPackages && this.state.selectedPackages.length && this.state.selectedProduct ?
                                            <div className='list-of-products-modal-footer'>
                                                <button type='button' onClick={() => this.addItem()}>Dodaj</button>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                                <div className='list-of-products-modal-body-right'>
                                    <h6>Izabrani proizvodi:</h6>
                                    <div className='selected-products'>
                                        {
                                            products.map((item, idx) => {
                                                return (
                                                    <div className='selected-product' key={idx}>
                                                        <div>
                                                            <h6>{item.Alias} - {item.Name}</h6>
                                                            <div className='selected-packages'>
                                                                {
                                                                    item && item.selectedPackages.map((pack, pidx) => {
                                                                        return (
                                                                            <h6>{pack.name}</h6>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='delete-product' onClick={() => {
                                                            this.removeItem(item)
                                                        }}>&times;</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>




                            </div>


                        </div>
                        :
                        null
                }
            </div>
        );

    }
}

export default ListOfProducts;